import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EntityState, EntityStore, getIDType, MultiActiveState, QueryEntity } from '@datorama/akita';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import {
  DataTableComponentEntityType,
  IDataTableColumnConfig,
  IDataTableConfig,
  IDataTableQueryResponse,
} from './data-table.interfaces';

export enum DataTableViewOptions {
  GRID = 'grid',
  TABLE = 'table',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
function createInitialStore<T extends DataTableComponentEntityType>(
  config: IDataTableConfig<T>,
): Partial<DataTableState<T>> {
  return {
    response: undefined,
    active: [],
    ui: {
      renderState: {
        pagination: {
          pageIndex: 0,
          pageSize: config.defaultPageSize ?? 25,
          length: 0,
        },
        sort:
          config.initialSort ??
          ({
            active: null,
            direction: null,
          } as unknown as Sort),
        filters: {},
      },
      viewMode: DataTableViewOptions.TABLE,
      visibleColumnNames: config.visibleColumns ?? [],
      recents: [],
      active: [],
    },
  };
}

function mapToColumnNames<T extends DataTableComponentEntityType>() {
  return map((columns: IDataTableColumnConfig<T>[]) => columns.map((c) => c.name));
}

export class DataTableStore<T extends DataTableComponentEntityType> extends EntityStore<DataTableState<T>> {
  constructor(config: IDataTableConfig<T>) {
    super(createInitialStore(config), {
      name: `DataTable-${config.name}`,
      resettable: true,
      idKey: config.idKey ?? 'id',
    });
    // FIXME: Potential breaking change!!!
    if (config.initialFilters) {
      this.update((state: DataTableState<T>) => ({
        ...state,
        ui: {
          ...state.ui,
          renderState: {
            ...state.ui.renderState,
            filters: {
              ...state.ui.renderState.filters,
              ...config.initialFilters,
            },
          },
        },
      }));
    }
  }
}

export class DataTableQuery<T extends DataTableComponentEntityType> extends QueryEntity<DataTableState<T>> {
  renderState$: Observable<DataTableRenderState> = this.select((state: DataTableState<T>) => state.ui.renderState).pipe(
    shareReplay(),
  );

  ui$: Observable<DataTableUIState<T>> = this.select('ui').pipe(shareReplay());

  entities$: Observable<T[]> = this.selectAll().pipe(shareReplay(1));

  isLoading$: Observable<boolean> = this.selectLoading().pipe(
    distinctUntilChanged(),
    switchMap((value) => {
      if (value === true) {
        return timer(500).pipe(mapTo(true));
      }
      return of(false);
    }),
  );

  response$: Observable<DataTableStateResponse<T>> = this.select((state: DataTableState<T>) => state.response);
  total$: Observable<number> = this.response$.pipe(map((response) => response?.pagination.total));

  displayableColumns$ = new BehaviorSubject<IDataTableColumnConfig<T>[]>(
    this.dataTableConfig.columns
      .filter((column: IDataTableColumnConfig<T>) => this.dataTableConfig.displayOrder?.includes(column.name))
      .sort((a, b) => {
        if (!this.dataTableConfig.displayOrder) {
          return 0;
        }
        const indexA = this.dataTableConfig.displayOrder.indexOf(a.name);
        const indexB = this.dataTableConfig.displayOrder.indexOf(b.name);
        return indexA - indexB;
      }),
  );

  displayableColumnNames$: Observable<string[]> = this.displayableColumns$.pipe(mapToColumnNames());

  initiallyVisibleColumns$: Observable<IDataTableColumnConfig<T>[]> = this.displayableColumns$.pipe(
    map((columns) =>
      columns.filter(
        (column: IDataTableColumnConfig<T>) =>
          this.dataTableConfig.displayOrder?.includes(column.name) &&
          this.dataTableConfig?.visibleColumns?.includes(column.name),
      ),
    ),
  );

  visibleColumns$: Observable<IDataTableColumnConfig<T>[]> = combineLatest([
    this.select((state) => state.ui.visibleColumnNames),
    this.displayableColumns$,
  ]).pipe(
    map(([columnsFromState, columns]) => {
      return columns.filter((column: IDataTableColumnConfig<T>) => {
        if (!this.dataTableConfig.displayOrder?.includes(column.name)) {
          return false;
        }
        if (columnsFromState) {
          return columnsFromState.includes(column.name);
        }
        return this.dataTableConfig.visibleColumns?.includes(column.name);
      });
    }),
  );

  visibleColumnNames$: Observable<string[]> = this.visibleColumns$.pipe(mapToColumnNames());

  renderColumns$: Observable<string[]> = this.visibleColumnNames$.pipe(
    map((input: string[]) => {
      if (this.dataTableConfig.selection) {
        return ['selection', ...input, 'actions'];
      }
      return [...input, 'actions'];
    }),
    shareReplay(),
  );

  filterableColumns$: Observable<IDataTableColumnConfig<T>[]> = of(
    this.dataTableConfig.columns
      .filter((column: IDataTableColumnConfig<T>) => !!column.filter)
      .sort((a, b) => {
        if (!this.dataTableConfig.filterOrder) {
          return 0;
        }
        const indexA = this.dataTableConfig.filterOrder.indexOf(a.name);
        const indexB = this.dataTableConfig.filterOrder.indexOf(b.name);
        return indexA - indexB;
      }),
  ).pipe(shareReplay());

  activeFilters$: Observable<DataTableFiltersState> = this.select((state) => state.ui.renderState.filters);

  activeFilterCount$: Observable<number> = this.select((state) =>
    state.ui.renderState.filters ? Object.keys(state.ui.renderState.filters).length : 0,
  );

  error$ = this.select('error');

  activeElements$: Observable<T[]> = this.select((state) => state.ui.active);

  constructor(
    protected override store: DataTableStore<T>,
    public dataTableConfig: IDataTableConfig<T>,
  ) {
    super(store);
  }

  setDisplayableColumns(columns: IDataTableColumnConfig<T>[]): void {
    this.dataTableConfig.columns = columns;
    this.displayableColumns$.next(
      this.dataTableConfig.columns
        .filter((column: IDataTableColumnConfig<T>) => this.dataTableConfig.displayOrder?.includes(column.name))
        .sort((a, b) => {
          if (!this.dataTableConfig.displayOrder) {
            return 0;
          }
          const indexA = this.dataTableConfig.displayOrder.indexOf(a.name);
          const indexB = this.dataTableConfig.displayOrder.indexOf(b.name);
          return indexA - indexB;
        }),
    );
  }
}

export type DataTableFiltersState = {
  [key: string]: unknown;
};

export interface DataTableUIState<T> {
  visibleColumnNames: string[];
  recents: T[];
  active: T[];
  viewMode: DataTableViewOptions;
  renderState: DataTableRenderState;
}

export interface DataTableRenderState {
  sort: Sort;
  pagination: PageEvent;
  filters: DataTableFiltersState;
  lastScroll?: [number, number];
}

type DataTableStateResponse<T> = Omit<IDataTableQueryResponse<T>, 'data'>;

export interface DataTableState<T> extends EntityState<T, getIDType<T>>, MultiActiveState {
  response: DataTableStateResponse<T>; // Data is stored in EntityState
  ui: DataTableUIState<T>;
}
