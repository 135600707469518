<div (keyup.enter)="triggerActionFn()" #componentContainer></div>

@if (!isComponent()) {
  <ng-container #inputContainer>
    @if (userTriggered) {
      <div>
        <ng-container *ngTemplateOutlet="matFormField"></ng-container>
      </div>
    } @else {
      <div>
        <ng-container *ngTemplateOutlet="matFormField"></ng-container>
      </div>
    }
  </ng-container>
}

<ng-template #matFormField>
  <mat-form-field>
    <mat-label>{{ column.label! | translate }}</mat-label>
    <div>
      <!-- Regular search input -->
      @if (mode === dataTableSearchHeaderMode.TEXT) {
        <input
          matInput
          type="text"
          (keyup.enter)="triggerActionFn()"
          [formControl]="control"
          [placeholder]="'Components.input.search.label' | translate"
        />
      }

      <!-- Datepicker input -->
      @if (mode === dataTableSearchHeaderMode.DATE) {
        <input
          matInput
          (focus)="datepickerRef.open()"
          type="text"
          (keyup.enter)="triggerActionFn()"
          (click)="datepickerRef.open()"
          (dateChange)="triggerActionFn()"
          [formControl]="control"
          [placeholder]="'SearchHeaderComponent.input.date.label' | translate"
          [matDatepicker]="datepickerRef"
        />
        <mat-datepicker #datepickerRef></mat-datepicker>
      }

      <!-- Select -->
      @if (mode === dataTableSearchHeaderMode.SELECT) {
        <mat-select
          [compareWith]="selectCompareFn"
          (selectionChange)="triggerActionFn()"
          [placeholder]="'Components.input.select.label' | translate"
          [formControl]="control"
        >
          @for (option of searchOptions$ | async; track option) {
            <mat-option [value]="option.value">
              {{ option.translatable | translate }}
            </mat-option>
          }
        </mat-select>
      }

      <!-- Autocomplete -->
      @if (mode === dataTableSearchHeaderMode.AUTOCOMPLETE) {
        <mat-chip-grid #chipList>
          @if (service.displayFilterValue(column); as display) {
            @if (isArray(display)) {
              @for (displayValue of display; track displayValue; let idx = $index) {
                <mat-chip-row
                  color="accent"
                  class="na-small"
                  (removed)="service.removeFilter(column.name, idx)"
                  [removable]="true"
                >
                  <div class="na-chip-text" [matTooltip]="displayValue">{{ displayValue }}</div>
                  <mat-icon matChipRemove matTooltip="Click to remove filter '{{ displayValue | translate }}'">
                    clear
                  </mat-icon>
                </mat-chip-row>
              }
            } @else {
              <mat-chip-row
                color="accent"
                class="na-small"
                (removed)="service.removeFilter(column.name)"
                [removable]="true"
              >
                <div class="na-chip-text" [matTooltip]="$any(display)">{{ display }}</div>
                <mat-icon matChipRemove matTooltip="Click to remove filter '{{ column.label! | translate }}'">
                  clear
                </mat-icon>
              </mat-chip-row>
            }
          }
          <input
            #autocompleteInput
            matInput
            [matChipInputFor]="chipList"
            [matChipInputFor]="chipList"
            [formControl]="control"
            [matAutocomplete]="autocompleteRef"
            type="text"
            autocomplete="off"
          />
        </mat-chip-grid>
        <mat-autocomplete
          [displayWith]="$any(column.content)"
          #autocompleteRef="matAutocomplete"
          (optionSelected)="autocompleteOptionSelected(autocompleteInput)"
        >
          @for (option of searchOptions$ | async; track option) {
            <mat-option [value]="option.value">
              {{ option.translatable | translate }}
            </mat-option>
          }
        </mat-autocomplete>
      }
    </div>
  </mat-form-field>
</ng-template>
