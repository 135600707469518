import { AsyncPipe, CommonModule, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInput, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { NamedFormControl } from '@navigatingart/named-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { DataTableFilterType } from '../../data-table.constants';
import {
  DataTableComponentEntityType,
  DataTableFiltersSearch,
  IDataTableColumnConfig,
  IDataTableColumnConfigFilter,
  IDataTableSearchOption,
} from '../../data-table.interfaces';
import { DataTableService } from '../../data-table.service';
import { DataTableQuery } from '../../data-table.store';
import { AbstractDataTableFilterComponent } from './abstract-data-table-filter.component';

@UntilDestroy()
@Component({
  selector: 'navigatingart-data-table-filters-input',
  templateUrl: './data-table-filters-input.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    MatFormField,
    MatLabel,
    TranslateModule,
    MatInput,
    ReactiveFormsModule,
    MatDatepickerInput,
    MatDatepicker,
    MatSelect,
    MatOption,
    MatChipGrid,
    MatChipRow,
    MatTooltip,
    MatIcon,
    MatChipRemove,
    MatChipInput,
    MatAutocompleteTrigger,
    MatAutocomplete,
    AsyncPipe,
    CommonModule,
  ],
})
export class DataTableFiltersInputComponent<T extends DataTableComponentEntityType> implements OnInit, OnDestroy {
  private componentFactoryResolver = inject(ComponentFactoryResolver);

  @Input()
  column!: IDataTableColumnConfig<T>;

  @Input()
  service!: DataTableService<T>;

  @Input()
  query!: DataTableQuery<T>;

  @Input()
  userTriggered = true;

  @Output()
  valueChanged = new EventEmitter<T>();

  @Output()
  triggerAction = new EventEmitter<DataTableFiltersSearch<T> | null>();

  control = new NamedFormControl('input', null);

  searchOptions$: Observable<IDataTableSearchOption[]> | undefined;

  dataTableSearchHeaderMode = DataTableFilterType;

  mode: DataTableFilterType | undefined;

  componentRef: ComponentRef<AbstractDataTableFilterComponent<T>> | undefined;

  isArray = Array.isArray;

  @ViewChild(MatSelect, { static: false })
  matSelect!: MatSelect;

  @ViewChild('componentContainer', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  ngOnInit() {
    if (!this.column) throw new Error(`@Input() column is required, but is ${this.column}`);

    this._detectMode();
    this._initControl();

    this.query.activeFilters$
      .pipe(
        untilDestroyed(this),
        filter((input) => !input),
      )
      .subscribe({
        next: () => this.clear(),
      });

    // Sync control value with state
    this.query.activeFilters$
      .pipe(
        untilDestroyed(this),
        filter((input) => !!input),
        distinctUntilKeyChanged(this.column.name),
        map((filters) => filters[this.column.name]),
      )
      .subscribe((filterValue) => this.control.setValue(filterValue));
  }

  private _initControl() {
    const optionsFn = (this.column.filter as IDataTableColumnConfigFilter<T>).optionsFn;
    switch (this.mode) {
      case DataTableFilterType.COMPONENT:
        // eslint-disable-next-line no-case-declarations
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
          AbstractDataTableFilterComponent<T>
        >(
          (this.column.filter as IDataTableColumnConfigFilter<T>).filterComponent as Type<
            AbstractDataTableFilterComponent<T>
          >,
        );
        this.viewContainerRef.clear();
        // FIXME: Remove deprecation
        this.componentRef =
          this.viewContainerRef.createComponent<AbstractDataTableFilterComponent<T>>(componentFactory);
        this.componentRef.instance.column = this.column;
        this.componentRef.instance.control = this.control;
        this.componentRef.instance.service = this.service;
        this.componentRef.instance.triggerActionFn = () => this.triggerActionFn();
        this._setupValueMapToOutput();
        break;
      case DataTableFilterType.SELECT:
        this.searchOptions$ = optionsFn ? optionsFn(undefined) : of([]);
        this._setupValueMapToOutput();
        break;
      case DataTableFilterType.AUTOCOMPLETE:
        this.searchOptions$ = this.control.valueChanges.pipe(
          startWith(''),
          filter((searchValue) => typeof searchValue === 'string' || !searchValue),
          filter((searchValue: string) => !searchValue || searchValue.length >= 3),
          map((searchValue: string) => (!searchValue ? undefined : searchValue)),
          debounceTime(250),
          switchMap((searchString: string | undefined) => (optionsFn ? optionsFn(searchString) : of([]))),
        );
        break;
    }
  }

  private _setupValueMapToOutput(): void {
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((searchValue) => !!searchValue),
      )
      .subscribe({
        next: (value) => this.valueChanged.emit(value),
      });
  }

  private _detectMode(): void {
    const filter = this.column.filter as IDataTableColumnConfigFilter<T>;

    this.mode = filter.type;
    if (this.mode !== undefined) {
      return;
    }

    // Autodetect
    // Use Date for names ending Mix/Max
    if (/^.*(?:Min|Max)$/.test(this.column.name)) {
      this.mode = DataTableFilterType.DATE;
    } else if (filter.optionsFn) {
      // Use select if options available
      this.mode = DataTableFilterType.SELECT;
    } else {
      this.mode = DataTableFilterType.TEXT;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectCompareFn(c1: any, c2: any): boolean {
    if (c1 === false && c2 === false) return true;
    if (c1 && c2 && c1.id && c2.id && c1.id === c2.id) return true;
    return c1 === c2;
  }

  isComponent(): boolean {
    return this.mode === DataTableFilterType.COMPONENT;
  }

  clear(): void {
    if (this.isComponent() && this.componentRef) {
      this.componentRef.instance.clear();
    } else {
      this.control.reset();
    }
  }

  triggerActionFn(): void {
    if (this.control.valid) {
      this.triggerAction.emit({
        column: this.column,
        value: this.control.value,
      });
    } else {
      const resetModes = [DataTableFilterType.TEXT, DataTableFilterType.DATE];
      if (this.mode !== undefined && resetModes.includes(this.mode)) {
        this.triggerAction.emit(null);
        this.control.reset();
      }
    }
  }

  autocompleteOptionSelected(input: HTMLInputElement): void {
    this.triggerActionFn();
    this.control.reset();
    input.value = '';
  }

  ngOnDestroy(): void {
    if (this.componentRef) this.componentRef.destroy();
  }
}
