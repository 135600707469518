import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControlStatus, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatError, MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NamedFormControl } from '@navigatingart/named-forms';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith } from 'rxjs';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'navigatingart-autocomplete-enum',
  templateUrl: './autocomplete-enum.component.html',
  styleUrl: './autocomplete-enum.component.scss',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    TranslateModule,
    MatPrefix,
    MatSuffix,
    MatInput,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    MatError,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class FormFieldAutocompleteEnumComponent extends FormFieldBaseComponent implements OnInit {
  @Input({ required: true })
  enum!: Record<string, string>;

  @Input()
  required = false;

  @Input()
  autocompleteLimit = 20;

  displayControl = new NamedFormControl('display', '', [this.validEnumValueValidator()]);

  autocompleteOptions$?: Observable<string[]>;

  destroyRef = inject(DestroyRef);

  validEnumValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.enum || !control.value) return null;
      return Object.values(this.enum).includes(control.value) ? null : { invalidValue: { value: control.value } };
    };
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // Update displayControl status from external control
    this.control.statusChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((controlStatus: FormControlStatus) => {
        if (controlStatus === 'DISABLED') {
          this.displayControl.disable({ emitEvent: false });
        } else if (controlStatus === 'VALID') {
          this.displayControl.enable({ emitEvent: false });
        }
      });

    // Update external control value from displayControl
    this.displayControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.displayControl.valid),
      )
      .subscribe({
        next: (value: Record<string, unknown>) => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateFromSource(value);
          if (!value) {
            this.control.reset();
          }
        },
      });

    this.autocompleteOptions$ = this.displayControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(''),
      filter((input) => typeof input === 'string'),
      debounceTime(250),
      distinctUntilChanged(),
      map((searchString: string) => {
        const enumValues: string[] = Object.values<string>(this.enum);
        return enumValues
          .filter((v: string) => {
            if (!searchString) return true;
            return v.includes(searchString);
          })
          .slice(0, this.autocompleteLimit);
      }),
      shareReplay(1),
    );

    if (this.control.value) {
      this.displayControl.setValue(this.control.value, { emitEvent: false });
    }

    if (this.control.disabled) {
      this.displayControl.disable();
    }
  }
}
