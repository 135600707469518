<mat-form-field [floatLabel]="floatLabel">
  <mat-label [translate]="label || floatLabel"></mat-label>

  @if (enableMarkdown) {
    <div
      markdown
      class="na-markdown"
      [class.na-hidden]="active || !control.value"
      (click)="activateInput()"
      [data]="control.value"
    ></div>
  }

  <span matSuffix>
    <ng-content select="[matSuffix]"></ng-content>
  </span>

  <span matPrefix>
    <ng-content select="[matPrefix]"></ng-content>
  </span>

  <input
    #inputControl
    [class.na-hidden]="enableMarkdown && !active && control.value"
    (focus)="activateInput()"
    (blur)="onBlur()"
    [formControl]="control"
    [matTooltip]="hint | translate"
    [name]="control.name"
    [placeholder]="placeholder ?? label | translate"
    [required]="control.required || control.errors?.['required'] === true"
    [readonly]="readonly"
    [type]="type"
    [attr.step]="type === inputTypes.NUMBER ? 'any' : null"
    title=""
    matInput
  />

  @for (errorName of control.errors | keyvalue; track errorName) {
    <mat-error [translate]="errorLabel(errorName.key)"> </mat-error>
  }

  @if (hintStart) {
    <mat-hint align="start">{{ hintStart | translate }}</mat-hint>
  }
  @if (hintEnd) {
    <mat-hint align="end">{{ hintEnd | translate }}</mat-hint>
  }
  @if (hintWithValue) {
    <mat-hint align="start">{{ hintWithValue.hintKey | translate: { hintValue: hintWithValue.hintValue } }}</mat-hint>
  }
</mat-form-field>
