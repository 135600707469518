import { KeyValuePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, output, viewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FloatLabelType,
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { NamedFormControl } from '@navigatingart/named-forms';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownComponent } from 'ngx-markdown';
import { FormFieldBaseComponent } from '../form-field-base.component';

export enum InputTypes {
  NUMBER = 'number',
}
@Component({
  selector: 'navigatingart-form-field-input',
  templateUrl: './form-field-input.component.html',
  styleUrls: ['./form-field-input.component.scss'],
  host: {
    '(keydown.enter)': 'enterKeyPressed.emit($event)',
  },
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    TranslateModule,
    MarkdownComponent,
    MatSuffix,
    MatPrefix,
    MatInput,
    ReactiveFormsModule,
    MatTooltip,
    MatError,
    MatHint,
    KeyValuePipe,
  ],
})
export class FormFieldInputComponent extends FormFieldBaseComponent implements OnInit {
  /**
   * Manually set the `type` attribute of the `input` element.
   */
  @Input()
  type = 'text';

  @Input()
  override control!: NamedFormControl;

  @Input()
  hintEnd!: string;

  @Input()
  hintStart!: string;

  @Input()
  hintWithValue?: { hintKey: string; hintValue: unknown };

  @Input()
  floatLabel: FloatLabelType = 'auto';

  @Input()
  enableMarkdown = false;

  @Input()
  readonly = false;

  @Input()
  placeholder: string | undefined = undefined;

  active = false;

  inputTypes = InputTypes;

  inputControl = viewChild<ElementRef<HTMLInputElement>>('inputControl');

  enterKeyPressed = output<KeyboardEvent>();

  activateInput(): void {
    if (this.active) {
      return;
    }
    this.active = true;
  }

  onBlur(): void {
    this.active = false;
  }

  focusInput() {
    this.activateInput();
    this.inputControl()?.nativeElement.focus();
  }
}
